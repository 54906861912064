.fluid{
  max-width: 1300px;
  width: 100%;
}
.group-list{
  border: none !important;
}
.list-item{
  border: none !important;
  padding: 5px 0 !important;
}
.section-product {
  background-image: url("..//..//assets/images/background/bg10.png");
  width: 100%;
  height: 80vh;
  margin: auto 0 !important;
  align-content: center !important;
}
.main_product{
 
}

.product_card {
    border: 1px solid rgb(222,226,230) !important;
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer !important;
    height: auto;
    overflow: hidden;
    min-height: 400px;
    height: 100%;
    margin-bottom: 20px;
  }
  .product_card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3), 0 6px 6px rgba(0, 0, 0, 0.2);
    border: none !important;
  }
  .product_card .card_img{
    width: 100%;
    height: 180px;

  }
  .product_card .card_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /* swipper card uchun */
  .product_card1{
    border: 1px solid rgb(222,226,230) !important;
  }