.no-reports-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px; /* Adjust according to the design */
    background-color: white; /* Light background color */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Light shadow */
  }
  
  .no-reports-icon {
    font-size: 50px;
    color: #f44336; /* Red color for the icon */
    margin-bottom: 10px;
    /* Add a background image or use pseudo-element for an icon if needed */
    content: "\f00d"; /* Example: Font Awesome close icon (if you're using Font Awesome) */
  }
  
  .no-reports-container p {
    font-size: 18px;
    color: #333; /* Text color */
    font-weight: 500;
  }
  