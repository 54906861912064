.scrollspy-container {
    display: flex;
    height: 100vh;
}

.scrollspy-example {
    position: relative;
    height: 100%;
    overflow-y: scroll;
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    margin-left: 20px;
}

.scrollspy-example h4 {
    padding-top: 20px;
    margin-top: 20px;
}
