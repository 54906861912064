.custom-thead {
    border-bottom: 2px solid green !important; /* Borderni qizil rangga o'zgartirish */
  }
  .no-border {
    border: none; /* Borderni olib tashlash */
    outline: none; /* Outline'ni olib tashlash */
  }
  
  .small-width {
    width: 150px; /* Elementlarning enini kichikroq qilish */
  }
  
  .vertical-line {
    border-left: 2px solid #000; /* Vertikal chiziqning rangi va qalinligi */
    height: 100%; /* Chiziqning balandligi */
    margin: 0 10px; /* Chiziqning ikki tomondan bo'sh joy */
  }
  ._dpdown{
    cursor: pointer;
    background-color: transparent !important;
    font-size: 18px;
    font-weight: 500;
    height: 60px;
    line-height: 60px;
    padding: 0 10px 0 40px;
    border: 0 !important;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  .dropdown-menu{
    width: 350px !important;
    padding: 20px 30px;
  }
  ._dpdown:hover{
    background-color: #e2e2e2 !important;
  }
  ._dpdown span{
    font-size: 17px;
    cursor: pointer;
    color: rgb(65,150,135);
  }
  ._edit{
    border: none;
    outline: none;
    
    transition: 0.3s ease all;
    padding: 3px 10px;
    border-radius: 3px;
  }
  ._edit span{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: #282828;
    font-family: "Roboto";
    white-space: nowrap;
  }
  ._edit:hover{
    background-color: #e2e2e2 !important;

    
  }
  ._th{
    position: relative;
  }
  ._th:not(:last-child)::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 30px;
    background: #e2e2e2;
    right: 0;
  }
  
  
  