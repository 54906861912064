// Testimonial 1
.testimonial-1{
	display: none !important;
	position: relative;
	padding: 120px 40px 40px;
	border-radius: 14px;
	@include transitionMedium;
	background-color: var(--secondary);
	border-color: var(--primary);
	
	&:after {
		position: absolute;
		content: "\f10c";
		top: 20px;
		left: 30px;
		line-height: 1;
		font-size: 90px;
		color: #fff;
		font-family: flaticon !important;
	}
	
	.testimonial-details{
	    display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.testimonial-info{
		display: flex;
		align-items: center;
	}
	p,
	.testimonial-name,
	.testimonial-position{
		color: #fff;
	}
	.testimonial-rating{
		ul{
			margin: 0 -5px;
			li{
				display: inline-block;
				color: #fff;
				padding: 0 5px;
				font-size: 20px;
				
				.gray-light{
					opacity: 0.3;
					color: #fff;
				}
			}
		}
	}
	.testimonial-text{
		margin-bottom: 30px;
		p{
			font-style: italic;
			font-size: 18px;
			opacity: 0.8;
		}
	}
	.testimonial-pic {
		overflow: hidden;
		height: 70px;
		width: 70px;
		min-width: 70px;
		border-radius: 50%;
		margin-right: 20px;
		position: relative;
		border: 2px solid #fff;
	}
	.testimonial-name{
		margin-bottom: 0;
	}
	.testimonial-position{
		opacity: 0.5;
		font-family: var(--font-family-title);
	}
	@include respond('phone'){
		padding: 80px 25px 25px 25px;
		&:after {
			top: 15px;
			left: 20px;
			font-size: 65px;
		}
		.testimonial-pic {
			height: 60px;
			width: 60px;
			min-width: 60px;
			margin-right: 15px;
		}
		.testimonial-text{
			margin-bottom: 20px;
			p {
				font-size: 15px;
			}
		}
		.testimonial-details{
			display: block;
		}
		.testimonial-rating{
			display: none;
		}
		.testimonial-name{
			font-size: 18px;
		}
	}
}
.testimonial-wrapper1{
	position: relative;
	overflow: hidden;
	
	.swiper-btn{
	    position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 99;
	}
	.swiper-slide{
		padding: 0 30px;
		opacity: 0.1;
		@include transitionMedium;
		max-width: 900px;
		
		&.swiper-slide-active{
			opacity:1;
			.testimonial-1{
				background-color: var(--primary);
				.testimonial-text{
					p{
						color: #fff;
					}
				}
				.testimonial-name {
					color: #fff;
				}
				.testimonial-position{
					color: #fff;
					
				}
			}
		}
	}
}

// Testimonial 2
.testimonial-wrapper2{
	&:before{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 28%, rgba(255,255,255,1) 29%, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 100%);
		background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,1) 29%,rgba(255,255,255,1) 75%,rgba(255,255,255,0) 100%);
		background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,1) 29%,rgba(255,255,255,1) 75%,rgba(255,255,255,0) 100%);
	}
	&:after{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.7) 34%, rgba(255,255,255,0.7) 67%, rgba(255,255,255,0.71) 68%, rgba(255,255,255,1) 97%, rgba(255,255,255,1) 99%);
		background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.7) 34%,rgba(255,255,255,0.7) 67%,rgba(255,255,255,0.71) 68%,rgba(255,255,255,1) 97%,rgba(255,255,255,1) 99%);
		background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.7) 34%,rgba(255,255,255,0.7) 67%,rgba(255,255,255,0.71) 68%,rgba(255,255,255,1) 97%,rgba(255,255,255,1) 99%);
	}
}
.testimonial-2{
	padding: 30px;
	background-color: #FFF4CF;
	border-radius: var(--border-radius-base);
	display: flex;
	width: 100%;
    margin: auto;
	
	@include respond('phone-land'){
		padding: 20px;
	}
	@include respond('phone'){
		display: block;	
	}
	
	.testimonial-media{
		width: 320px;
		height: auto;
		margin-right: 30px;
		min-width: 320px;
		img{
			border-radius: var(--border-radius-base);
			filter: grayscale(0.8);
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		@include respond('tab-port'){
			width: 250px;
			min-width: 250px;
		}
		@include respond('phone-land'){
			width: 200px;
			min-width: 200px;
			margin-right: 20px;
		}
		@include respond('phone'){
			width: 100%;
			min-width: 100%;
			margin-right: 0;
			margin-bottom: 15px;
		}
	}
	.testimonial-content{
		.testimonial-title{
			color: var(--primary);
		}
		.testimonial-text{
			margin-bottom: 30px;
			a{
				color: var(--primary);
				text-decoration: underline;
			}
		}
		.testimonial-info{
			display: flex;
			align-items: center;
			.quotes{
				i{
					color: var(--primary);
					font-size: 50px;
					margin-right: 20px;
				}
			}
			.testimonial-name{
				margin-bottom: 0;
			}
		}
		@include respond('phone-land'){
			.testimonial-text {
				margin-bottom: 15px;
				font-size: 15px;
			}
		}
	}
}

// Testimonial
.testimonial-wrapper3{
	overflow: hidden;
	
	&:before{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background: -moz-linear-gradient(left,  rgba(20,24,24,0) 0%, rgba(20,24,24,1) 28%, rgba(20,24,24,1) 29%, rgba(20,24,24,1) 75%, rgba(20,24,24,0) 100%);
		background: -webkit-linear-gradient(left,  rgba(20,24,24,0) 0%,rgba(20,24,24,1) 28%,rgba(20,24,24,1) 29%,rgba(20,24,24,1) 75%,rgba(20,24,24,0) 100%);
		background: linear-gradient(to right,  rgba(20,24,24,0) 0%,rgba(20,24,24,1) 28%,rgba(20,24,24,1) 29%,rgba(20,24,24,1) 75%,rgba(20,24,24,0) 100%);
	}
	&:after{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background: -moz-linear-gradient(top,  rgba(20,24,24,1) 0%, rgba(20,24,24,0.7) 34%, rgba(20,24,24,0.7) 67%, rgba(20,24,24,0.71) 68%, rgba(20,24,24,1) 97%, rgba(20,24,24,1) 99%);
		background: -webkit-linear-gradient(top,  rgba(20,24,24,1) 0%,rgba(20,24,24,0.7) 34%,rgba(20,24,24,0.7) 67%,rgba(20,24,24,0.71) 68%,rgba(20,24,24,1) 97%,rgba(20,24,24,1) 99%);
		background: linear-gradient(to bottom,  rgba(20,24,24,1) 0%,rgba(20,24,24,0.7) 34%,rgba(20,24,24,0.7) 67%,rgba(20,24,24,0.71) 68%,rgba(20,24,24,1) 97%,rgba(20,24,24,1) 99%);
	}
	
	.swiper{
		margin-right: -200px;
	}
	.resize-wrapper{
		padding-bottom: 80px;
		@include respond('tab-port'){
			padding-bottom: 40px;			
		}
	}
	.swiper-btn{
		display: flex;
		
		.btn-prev,
		.btn-next{
			padding: 0 20px;
			font-size: 30px;
			opacity: 0.7;
			cursor: pointer;
			
			&:hover{
				opacity: 1;
			}
		}
	}
	@media only screen and (max-width: 1191px){
		.swiper{
			margin-right: -35%;
		}
	}
	@include respond('tab-port'){
		.swiper{
			margin-right: 0;
		}
	}
	@include respond('phone'){
		.swiper-btn{
			margin-bottom: 20px;
			
			.btn-prev,
			.btn-next{
				padding: 0 5px;
				font-size: 20px;
				
				svg{
					width: 12px;
				}
			}
		}
	}
}
.testimonial-3{
	background-color: #FFF;
	border-radius: var(--border-radius-base);
	display: flex;
	overflow: hidden;
	
	@include respond('phone'){
		display: block;
	}	
	.testimonial-media{
		height: auto;
		width: 312px;
		min-width: 312px;
		background-color: #003B4A;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			opacity: 0.7;
		}
		@include respond('tab-land'){
			width: 250px;
			min-width: 250px;
		}
		@include respond('phone-land'){
			width: 220px;
			min-width: 220px;
		}
		@include respond('phone'){
			width: 100%;
			min-width: 100%;
			height: 250px;
		}
	}
	.testimonial-content{
		padding: 35px;
		position: relative;
		
		@include respond('tab-land'){
			padding: 25px;
		}
		
		.testimonial-title{
			color: var(--primary);
			font-weight: 500;
			margin-bottom: 15px;
			@include respond('phone-land'){
				margin-bottom: 10px;
				font-size: 16px;
			}
		}
		.testimonial-text{
			margin-bottom: 30px;
			a{
				color: var(--primary);
				text-decoration: underline;
			}
			@include respond('tab-land'){
				margin-bottom: 20px;
			}
			@include respond('phone-land'){
				font-size: 15px;
			}
		}
		.testimonial-info{
			display: flex;
			align-items: center;
			.quotes{
				i{
					color: var(--primary);
					font-size: 50px;
					margin-right: 20px;
				}
			}
			.testimonial-name{
				margin-bottom: 0;
			}
		}
		&:after{
			content: "";
			position: absolute;
			left: -25px;
			top: 40px;
			border-left: 25px solid transparent;
			border-top: 30px solid #fff;
			z-index: 1;
		}
	}
	&.right{
		.testimonial-content:after {
			left: auto;
			border-left: 0;
			border-top: 30px solid #fff;
			right: -25px;
			border-right: 25px solid transparent;
		}
	}
}

