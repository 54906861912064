._title{
    font-weight: 500 !important;
    /* line-height: 25px !important; */
    margin: 5px 0 !important;
    opacity: 0.9;
    font-size: 17px !important;
    align-items: baseline !important;
}
._date{
    font-weight: 500 !important;
    line-height: 25px !important;
    margin: 5px 0 !important;
    opacity: 0.8;
    font-size: 14px !important;
    align-items: baseline !important;
}
.max-height{
    max-height: 500px;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
    transition: 0.5s ease-all;
    border: 1px solid gainsboro !important;
}

.card-footer{
   background: transparent !important;
   border-top: none !important;
   padding-top: 0 !important;
   padding-bottom: 14px !important;
}
.card-body{
    padding: 10px 20px 0px 20px !important;
}
._icon{
    cursor: pointer;
}
/* search */
.search-filter-container {
    /* display: flex;
    align-items: center; */
    padding: 0 15px !important;
  
  }
  
  .search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
  }
  
  .search-bar input {
    border: none;
    outline: none;
    flex: 1;
  }
  
  .search-bar .fa-search {
    margin-left: 5px;
    color: #aaa;
  }
  
  .filter-bar select {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 7px;
    width: 100% !important;
  }
  
  /* .results-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;
  } */
  .filter-bar{
    margin-bottom: 0 !important;
    padding: 0px 0px !important;
    background: transparent !important;
  }
  .results-list li {
    padding: 10px;
    cursor: pointer;
  }
  
  .results-list li:hover {
    background: #f0f0f0;
  }
  /* Report detail css */

  .r_summary {
    padding: 0px 30px !important;

  }
  ._h4{
    margin-left: 30px;
  }
  /* Search componenet */
  .search-filter{
    padding: 10px 20px;
    background-color: white;
    box-shadow: 
    0 4px 8px rgba(0, 0, 0, 0.1), /* Soft shadow */
    0 6px 20px rgba(0, 0, 0, 0.1); /* Deeper shadow */
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 5px;
  }
  .search-bar {
    display: flex;
    width: 100%;
    padding: 5px 10px !important;
    align-items: center;
    border: none;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    padding: 5px;
    background-color: rgb(243,245,251);
  }
  
  .search-bar input {
    border: none;
    outline: none;
    padding: 5px;
    margin-left: 10px;
    flex: 1;
    background: rgb(243,245,251);
  }
  
  .search-bar .search-button,
  .search-bar .clear-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .search-bar .clear-button {
    color: green; /* Optional: Change color to differentiate */
  }
  /* Filter Button */
  .filter-button button {
    background-color: rgb(27,130,113);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  
  .filter-button i {
    margin-right: 5px;
  }
  /* Filter-Panel */

  .filter-show{
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    padding: 10px 25px !important;
  }
  .filter-show ._dh5 {
    font-size: 18px;
   margin-bottom: 15px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  .filter-show ._dh5 h5{
    margin-bottom: 0;
    font-size: 17px;
  }
  .filter-sidebar {
    padding: 10px 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;

  }
  

  .filter-section {
    margin-bottom: 20px;
  }
  
  .filter-section h6 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .filter-section select,
  .filter-section input[type='checkbox'] {
    margin-right: 10px;
  }
  
  .filter-section select {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  label {
    font-size: 14px;
  }
  
  input[type='checkbox'] {
    margin-right: 5px;
    transform: scale(1.2);
  }
  /* dropdown-input */
  .dropdown {
    position: relative;
    display: inline-block;
    width: 100% !important;
    text-align: left;
  }
  
  .dropdown-button {
    text-align: left;
    width: 100% !important;
    background-color: #f9f9f9;
    color: black;
    padding: 5px 10px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .dropdown-content {
    display: none;
    height: 250px;
    overflow-y: scroll;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 5px;
  }
  .dropdown-content::-webkit-scrollbar {
    width: 8px;  /* Set the width of the scrollbar */
  }
  
  .dropdown-content::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar color */
    border-radius: 10px; /* Rounded edges */
  }
  
  .dropdown-content::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color on hover */
  }
  
  .dropdown-content::-webkit-scrollbar-track {
    background: #f1f1f1;  /* Track color */
  }
  
  .dropdown-content::-webkit-scrollbar-track:hover {
    background: #ddd; /* Track hover color */
  }
  
  /* Scrollbar height for horizontal scroll */
  .dropdown-content::-webkit-scrollbar:horizontal {
    height: 8px; /* Set the height of the horizontal scrollbar */
  }
  .dropdown-content .dropdown-item {
    color: black;
    padding: 5px 12px;
    text-decoration: none;
    display: block;
    cursor: pointer;

  }
  
  .dropdown-content .dropdown-item:hover {
    /* background-color: #f1f1f1; */
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  