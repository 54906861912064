/* Asosiy uslublar */
.wrapper{
    display: flex;
    justify-content: space-between;
    
}
.section1 {
    margin: 0 !important;
    padding-left: 30px !important;
}
.nav-li{
    padding: 3px 5px;
    border-left: 4px solid #e2e2e2;
}
.nav-link{
    padding: 5px 10px !important;
    border-bottom: 1px solid #e2e2e2;
    color: rgb(49, 41, 41);
    font-weight: 600;
    font-size: 16px;
}
.activ{
    border-left: 4px solid red;
}
ul{
    padding: 10px;
}
nav {
    width: 300px;
    float: left;
    height: 100vh;
    position: sticky;
    top: 140px;
    overflow-y: auto;
    background-color: #f8f9fa;
  }
  
  .content {
    margin-left: 300px;
    /* padding: 20px; */
  }
  
  /* Media queries */
  @media (max-width: 768px) {
    .wrapper{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
    nav {
      width: 100%;
      float: none;
      position: static  !important;
      height: auto;
      top: auto;
      margin-bottom: 20px;
    }
    .content {
      margin-left: 200px;
      padding: 10px;
    }
    .section1{
        padding-left: 0 !important;
    }
  }
  
  @media (max-width: 480px) {
    nav {
      /* Ekran o'lchami juda kichik bo'lsa, nav ni butunlay yashirish mumkin */
      display: none;
    }
  
    .content {
      padding: 5px;
    }
  }
  