.profile-menu {
    position: relative;
    display: inline-block;
  }
  
  .profile-button {
    background-color: white;
    border: none;
    cursor: pointer;
        margin-top: 2px ;

  }
  
  .profile-button:hover {
  
  }
  
  .dropdown-menus {
    display: block;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 100%;
    transform: translateX(20%);
    margin-top: 10px;
    width: 160px;
    
  }
  
  .dropdown-menus a {
    color: lightslategray;
    padding: 2px 10px 2px 10px;
    text-decoration: none;
    display: block;
    font-weight: normal;
    font-size: 15px;
    
    /* border-bottom: 1px solid lightgrey; */
  }
  .dropdown-menus a:last-child{
    border: none;
  }
  
  .dropdown-menus a:hover {
    background-color: whitesmoke;
    
  }
  .dropdown-menus p{
    background: #1b8271;
    color: white;
    padding: 5px 5px;
    margin: 0;
    font-size: 15px;
  }
  .button-lang{
   display: block;
   width: 100%;
   transition: 0.3s ease;
   font-weight: normal;
   color: lightslategray;
   opacity: 1;
   text-transform: inherit;
  }
  .button-lang:hover{
    background: #1b8271 !important;
    color: white;
  }
  